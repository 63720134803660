.close{
    display: flex;
    cursor: pointer;
    margin-right: -25px;
    margin-bottom: -4px;
    .closeSpan{
        height: 27px;
        width: 27px;
    }
}

.attachName{
    display:inline-block;
    margin-top:7px;
    margin-bottom: auto;
    margin-right: 8px;
    cursor: pointer;
}

.attachSize{
    display:flex;
    margin-top: 3px;
    margin-bottom:auto;
    margin-left: 3px;
    font-size: 14px;
    font-style: italic;
    color: #959595;
}

.containercolour{
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    padding: 4px;
    padding-right: 8px;
    padding-left: 8px;
    height: 100%;
}

.imagestyle{
    height:10rem;
    object-fit: cover;
    display: flex;
    border-radius: 3px;
}

.downloadtag{
    display: none;
}

@media (min-width: 768px) {
    .containerwidth{
        width: 80%;
    }
}
@media (max-width: 768px) {
    .containerwidth{
        width: 100%;
    }
}
