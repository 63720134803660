.last7days{
  margin-left: 20px;
  margin-right: 20px;
  font-size: 14px;
  display: flex;
  align-items: center;
}

.progress {
    height: 5px;
    padding: 0;
    margin-bottom: 0px;
  }

  .inputstyle{
    margin-right: 4px;
    height: 11px;
  }

  .margin-top-bottom{
    margin-top: auto;
    margin-bottom: auto;
  }

  .iconStyle{
    color:#a6a6a6;
    font-size: 20px !important;
    margin-left: 5px;
  }

  .pageContainer{
    margin-top: 50px;
    overflow-x: auto;
  }