.container {
  display: flex;
  align-items: center;
  gap: 5px;
  width: 100%;
  background-color: white;
  height: 40px;
  border-radius: var(--input-border-radius);
  border: solid 1px var(--border-color-light);
  padding-left: 12px;
  
  .search {
    outline: 0;
    color: #363636;
    font-size: 13px;
    border: 0;
    background-color: transparent;
  
    &::placeholder {
      font-size: inherit;
      color: #d4d4d4;
    }
  }
  
}

.transparent {
  background-color: transparent !important;
  border: 0 !important;
}