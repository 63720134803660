html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
}



.alertbox {
  top: 25%;
  left: 35%;
  padding: 20px;
  background: rgb(209, 95, 95);
  border-radius: 5px;
  width: 30%;
  position: fixed;
  z-index: 999999;

}

.Orgdetails img {
  margin-top: 10px;
  max-width: 200px;
  max-height: 70px;
}

.historical_toggle_button {
  position: absolute;
  width: 75px;
  height: 42px;
  right: 250px;
  top: 10px;
  margin: 0;
  color: #eeeeee;
  /* background: #0652dd; */
  mix-blend-mode: normal;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 40px;
}
.Trackrisks {
  width: 450px;
  height: 421px;

  background: #ffffff;
  border-radius: 5px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.05), 0 6px 10px 0 rgba(0, 0, 0, 0.09);
}

.Trackrisks h3 {
  /* font-size: 20px; */
  border-bottom: 2px solid #c27a25;
  font-weight: bold;
  padding: 4px 30px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.Riskbyproperty {
  width: 500px;
  height: 450px;

  background: #ffffff;
  border-radius: 5px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.05), 0 6px 10px 0 rgba(0, 0, 0, 0.09);
}
.Riskbyproperty h3 {
  /* font-size: 20px; */
  border-bottom: 2px solid #c27a25;
  font-weight: bold;
  padding: 10px 30px;
  margin-top: 0;
  margin-bottom: 30px;
}

.Riskmatrix {
  display: grid;
  grid-template-columns: 60px repeat(4, 1fr);
  grid-row-gap: 20px;
  text-align: center;
}
.Riskmatrix h6 {
  font-size: 12px;
  /* font-weight: 800; */
  color: #818080;
  margin: 5px;
}

.highbox {
  width: 70px;
  height: 32px;
  text-align: center;
  color: #eeeeee;
  background: #ff0000;
  cursor: pointer;
  padding: 5px;
  margin:0 auto;}

.highbox a {
  color: #eeeeee;
  text-decoration: none;
}

.highbox:hover {
  transform: scale(1.2, 1.2);
}

.mediumbox {
  width: 70px;
  height: 32px;
  text-align: center;
  color: #eeeeee;

  background: #f29f21;
  cursor: pointer;
  padding: 5px;
  margin:0 auto;
}

.mediumbox a {
  color: #eeeeee;
  text-decoration: none;
}
.mediumbox:hover {
  transform: scale(1.2, 1.2);
}
.lowbox {
  width: 70px;
  height: 32px;
  text-align: center;
  color: #eeeeee;
  cursor: pointer;
  padding: 5px;
  margin:0 auto;
  background: #26a759;
}

.lowbox a {
  color: #eeeeee;
  text-decoration: none;
}
.lowbox:hover {
  transform: scale(1.2, 1.2);
}

.negligiblebox {
  width: 70px;
  height: 32px;
  text-align: center;
  color: #eeeeee;
  cursor: pointer;
  padding: 5px;
  margin:0 auto;
  background: #c4c4c4;
}

.negligiblebox a {
  color: #eeeeee;
  text-decoration: none;
}

.negligiblebox:hover {
  transform: scale(1.2, 1.2);
}

.App {
  position: relative;
  height: 50%;
  width: 97%;
  left: 20px;
  margin-top: 50px;
  background: #ffffff;
  border-radius: 5px;
}

.Alertdetails {
  /* position: absolute; */
  width: calc(100% - 50px);

  height: 447px;
  /* left: 570px;
  top: 265px; */

  background: #ffffff;
  border-radius: 5px;
  overflow-y: scroll;
  overflow-x: hidden;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.05), 0 6px 10px 0 rgba(0, 0, 0, 0.09);
}

.Alertdetails h3 {
  /* font-size: 20px; */
  font-weight: bold;
  padding: 10px 30px;
  margin-top: 0px;
  margin-bottom: 10px;
  border-bottom: 2px solid #ff0000;
  width: 100%;
}
.dashboard__risk {
  width: 450px;
  height: 200px;

  background: #ffffff;
  border-radius: 5px;
  box-sizing: border-box;
  margin-bottom: 20px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
/*  */

.balkerne button {
  display: inline-block;
  border: none;
  padding: 1rem 2rem;
  margin: 10px;
  text-decoration: none;
  background: #0069ed;
  color: #ffffff;
  font-size: 0.8em;
  cursor: pointer;
  text-align: center;
  transition: background 250ms ease-in-out, transform 150ms ease;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.balkerne button:hover,
.balkerne button:focus {
  background: #0053ba;
}

.balkerne button:focus {
  outline: 1px solid #fff;
  outline-offset: -4px;
}

.balkerne button:active {
  transform: scale(0.99);
}

@media (min-width:901px){

  .main-page {
    min-height: 100%;
  }
  .pagecontent {
    display: grid;
    grid-template-columns: 500px auto;
    grid-column-gap: 50px;
    grid-row-gap: 50px;
    margin-top: 40px;
    margin-left: 20px;
  }

  .Orgdetails {
    width: 500px;
    height: 140px;
    background: #ffffff;
    border-radius: 20px;
    text-align: center;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.05), 0 6px 10px 0 rgba(0, 0, 0, 0.09);
  }
  .Alertdetails {
    /* position: absolute; */
    width: calc(100% - 50px);
  
    height: 447px;
    /* left: 570px;
    top: 265px; */
  
    background: #ffffff;
    border-radius: 5px;
    overflow-y: scroll;
    overflow-x: hidden;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.05), 0 6px 10px 0 rgba(0, 0, 0, 0.09);
  }

  #propertyrisktable{
    display: block;
  }
  #dashboard_map_main{
    display: block;

  }
}

@media (max-width:900px){
  .main-page {
    min-height: 50%;
  }
  .pagecontent {
    display: grid;
    grid-template-rows: auto auto auto;
    /* grid-column-gap: 50px; */
    grid-row-gap: 50px;
    margin-top: 40px;
    margin-left: 20px;
  }

  .Orgdetails {
    width: calc(100% - 50px);
    height: 140px;
    background: #ffffff;
    border-radius: 20px;
    text-align: center;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.05), 0 6px 10px 0 rgba(0, 0, 0, 0.09);
  }
  .Alertdetails {
    /* position: absolute; */
    width: calc(100% - 50px);
  
    /* height: 447px; */
    /* left: 570px;
    top: 265px; */
  
    background: #ffffff;
    border-radius: 5px;
    /* overflow-y: scroll;
    overflow-x: hidden; */
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.05), 0 6px 10px 0 rgba(0, 0, 0, 0.09);
  }

  #propertyrisktable{
    display: none;
  }
 
  #dashboard_map_main{
    display: none;

  }

}