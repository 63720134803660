
.riskratingitem {
  width: 190px; 
  display: grid;
  justify-items: center;
}
.rating_text {
  margin-top:5px;
  min-height: 40px;
  max-width: 120px;
  text-align: center;
  font-size: 15px;
  font-weight: bold;
  color: #000000;
}
