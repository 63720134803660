.PropertyDashboard {
    background-color: var(--bg-color);
  }
  
  .dashboard_right {
    display: grid;
    grid-template-rows: repeat(3, max-content);
    height: max-content;
    grid-row-gap: 30px;
    margin-right: 20px;
  }
  
  .dashboard_left {
    display: grid;
    grid-template-columns: max-content auto;
    height: max-content;
    grid-column-gap: 5%;
  }
  
  .test_dashboard__risk {
    width: 250px;
    height: 800px;
  
    background: #ffffff;
    border-radius: 5px;
  
    box-sizing: border-box;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.05), 0 6px 10px 0 rgba(0, 0, 0, 0.09);
  }
  
  .dashboard__risk_breakdown {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: stretch;
    align-content: space-between;
  }
  
  .dashboard__risk_score_title {
    font-size: 17px;
    padding: 5px 30px;
    margin-top: 0;
    margin-bottom: 0;
    border-bottom: 2px solid rgba(139, 217, 254, 1);
    width: 100%;
  }
  .dashboard__risk_score {
    margin-top: 0;
  }
  
  .dashboard__risk_score--main {
    font-size: 50px;
    text-align: center;
    width: 100%;
  }
  .dashboard__risk_score--small {
    font-size: 32px;
  }
  
  .dashboard__risk_score--sub {
    font-size: 15px;
    font-weight: 400;
    white-space: nowrap;
    margin: 10px;
    display: grid;
    grid-template-rows: auto auto;
  }
  #increasedrating {
    color: red;
    font-size: 30px;
  }
  .dashboard__risk_breakdown {
    margin-top: 10px;
  
    display: grid;
    flex-wrap: wrap;
    justify-content: center;
    align-items: stretch;
    align-content: center;
    row-gap: 5px;
  }
  
  .dashboard__risk_category {
    display: inline;
  }
  
  .dashboard__risk_bar {
    -webkit-appearance: none;
    appearance: none;
  }
  .dashboard__risk_score--green {
    color: rgba(67, 162, 59, 1);
  }
  
  #rating.dashboard__risk_bar[value] {
    flex: 0 0 auto;
    height: 15px;
    width: 180px;
    border: none;
    box-sizing: border-box;
  }
  
  #demoscore.dashboard__risk_bar[value] {
    flex: 0 0 auto;
    height: 10px;
    width: 180px;
    border: none;
    box-sizing: border-box;
  }
  
  .dashboard__risk_bar--low[value] {
    background-color: rgba(67, 162, 59, 0.25);
  }
  
  .dashboard__risk_bar--medium[value] {
    background-color: rgba(237, 150, 0, 0.25);
  }
  
  .dashboard__risk_bar--high[value] {
    background-color: rgba(229, 41, 34, 0.25);
  }
  
  .dashboard__risk_bar--low[value]::-moz-progress-bar {
    background-color: rgba(67, 162, 59, 1);
  }
  
  .dashboard__risk_bar--medium[value]::-moz-progress-bar {
    background-color: rgba(237, 150, 0, 1);
  }
  
  .dashboard__risk_bar--high[value]::-moz-progress-bar {
    background-color: rgba(229, 41, 34, 1);
  }
  
  .dashboard__risk_bar--low[value]::-webkit-progress-value {
    background-color: rgba(67, 162, 59, 1);
  }
  
  .dashboard__risk_bar--medium[value]::-webkit-progress-value {
    background-color: rgba(237, 150, 0, 1);
  }
  
  .dashboard__risk_bar--high[value]::-webkit-progress-value {
    background-color: rgba(229, 41, 34, 1);
  }
  
  .progress {
    height: 5px;
    padding: 0;
    margin-bottom: 0px;
  }
  #dashboardmap{
    max-width: 700px;
  
  }
  .dashboard__map {
    height: 600px;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.05), 0 6px 10px 0 rgba(0, 0, 0, 0.09);
    border-radius: 2px;
    border: 1px solid rgba(51, 51, 51, 0.25);
    box-sizing: border-box;
    overflow: hidden;
  }
  .dashboard_map_area {
    width: 100%;
    height: 700px;
    left: 20px;
  
    padding: 5px 5px;
  
    background: #ffffff;
    border-radius: 5px;
    overflow: hidden;
  }
  
  .dashboard__map_controls {
    left: 20px;
    height: 40px;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  .dashboard__alerts {
    width: 100%;
    left: 570px;
    height: 250px;
  
    overflow-y: scroll;
    overflow-x: hidden;
  
    background: #ffffff;
    border-radius: 5px;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.05), 0 6px 10px 0 rgba(0, 0, 0, 0.09);
  }
  .Alertsriskreport {
    width: 127px;
    height: 25px;
    float: right;
    margin-top: 0px;
    background: #0652dd;
    border-radius: 10px;
  
    font-size: 15px;
    line-height: 18px;
    color: #ffffff;
  }
  
  .Alertsriskreport:hover {
    background: #2166e6;
  }
  
  .Mitigationsriskreport {
    width: 127px;
    height: 25px;
    float: right;
    margin-top: 0px;
    background: #0652dd;
    border-radius: 10px;
  
    font-size: 15px;
    line-height: 18px;
    color: #ffffff;
  }
  
  .Mitigationsriskreport:hover {
    background: #2166e6;
  }
  
  .mitigationurl {
    cursor: pointer;
  }
  
  
  .dashboard__recommendations {
    width: 100%;
    left: 570px;
    top: 500px;
    height: 215px;
    overflow-y: scroll;
    overflow-x: hidden;
  
    background: #ffffff;
    border-radius: 5px;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.05), 0 6px 10px 0 rgba(0, 0, 0, 0.09);
  }
  
  .dashboard-card-body {
    overflow-y: auto;
    padding: 12px 4px 12px 0;
    height: min(fit-content, 210px);
  }
  
  .dashboard__mitigation {
    width: 100%;
    overflow: auto;
    left: 570px;
    top: 735px;
    height: 250px;
    overflow-y: scroll;
    overflow-x: hidden;
  }
  
  .dashboard__notices_title {
    font-size: 20px;
    font-weight: bold;
    padding: 5px 30px;
    margin-top: 0;
    margin-bottom: 0;
    width: 100%;
    flex: 0;
  }
  
  .dashboard__notices_title--recommendations {
    border-bottom: 2px solid rgba(255, 203, 127, 1);
  }
  
  .dashboard__notices_title--alerts {
    border-bottom: 2px solid rgba(230, 148, 146, 1);
  }
  
  .dashboard__notices_title--activity {
    border-bottom: 2px solid rgba(133, 153, 157, 1);
  }
  
  .notice__box {
    margin-left: 10px;
    flex: 0 0 0;
    display: flex;
    align-items: center;
    margin-top: 16px;
  }
  
  .notice__icon {
    flex: 0 0 auto;
    width: 40px;
    height: 40px;
    margin-right: 12px;
    background-color: rgba(0, 0, 0, 0.1);
  }
  
  .notice__content {
    display: flex;
    flex-direction: column;
  }
  
  .notice__title {
    margin: 0;
    font-size: 16px;
    font-weight: 600;
  }
  
  .notice__text {
    margin: 0;
    font-size: 16px;
    font-weight: 400;
  }
  
  .dashboardcontent {
    background-color: var(--bg-color);
    margin-bottom: 50px;
    margin-left: 20px;
    grid-column-gap: 25px;
  }
  
  @media (min-width: 1200px) {
    .dashboardcontent {
      margin-top: 30px;
      display: grid;
      grid-template-columns: repeat(2,50%);
      width: calc(100% - 40px);
    }
  }
  
  @media (max-width: 1201px) {
    .dashboardcontent {
      position: relative;
      top: 60px;
      width: calc(100% - 20px);
    }
  
    #dashboardleft {
      display: none;
    }
  
    #viewmore {
      display: none;
    }
  }
  
  @media (max-width: 430px){
    .button-drafts{
      margin-left: 0px;
      margin-top:10px;
    }
  }
  
  .actionContainer {
    margin-top: 15px;
    margin-left: 15px;
    display: flex;
    flex-wrap: wrap;
    column-gap: 0.625rem;
    row-gap: 0.425rem;
  }
  
  .container_property_attributes{
    margin-top: 5px;
  }
  .container_property_attribute{
    display: flex;
    flex-direction: column;
    margin-left: 12px;
    margin-bottom: 0.725rem;
    row-gap: 0.125rem;
  }
  
  .property_attr_title{
    font-size:15px;
    font-weight: 500;
    color: #000000;
  }
  
  .property_attr_value{
    font-size:15px;
  }