.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiMenu-paper.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiPopover-paper.css-1bmo1sp-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper{
    /* top: 17rem !important;
    height: 26rem !important; */
}
.card-header-container{
    display: flex;
    justify-content: space-between;
}
.modal-container{
    display: flex;
    justify-content: flex-end;
}
.box-container{
    min-width: 120px;
    margin-top: 16px;
    margin-bottom: 15px;
}