.dashboard__map_risk {
    height: 350px;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.05), 0 6px 10px 0 rgba(0, 0, 0, 0.09);
  
    min-width: 400px;
    z-index: 1;
    border-radius: 2px;
    border: 1px solid rgba(51, 51, 51, 0.25);
    box-sizing: border-box;
    overflow: hidden;
  }