.large {
  width: 36px;
}

.medium {
  width: 32px;
}

.normal {
  width: 24px;
}

.small {
  width: 16px;
}