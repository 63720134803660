// @import url("https://fonts.googleapis.com/css?family=Lato");


fieldset {
  legend {
      width: unset;
  }
}

// Don't remove this block, it's needed for MuiDataGrid footer to be displayed correctly
p { 
  margin-bottom: auto;
  margin-top: auto;
}


// -- Scrollbar --

/* size*/
*::-webkit-scrollbar {
  height: 8px;
  width: 8px;
}

/* background of the scrollbar except button or resizer */
*::-webkit-scrollbar-track {
  background-color: rgba(0,0,0,0);
}

/* scrollbar itself */
*::-webkit-scrollbar-thumb {
  background-color: #babac0;
  border-radius: 16px;
  border: 0px solid rgba(0,0,0,1);
}

/* set button(top and bottom of the scrollbar) */
*::-webkit-scrollbar-button {
  display:none;
}