.containerAttachedFile{
    display: flex;
    justify-content: center;
}

@media only screen and (min-width: 768px) {
.containerGridAttachFile{
    margin-top: 20px;
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    row-gap: 2.25rem;
    width: 100%;
}
.containerGridOtherFiles{
    margin-top: 20px;
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    column-gap: 2.25rem;
    row-gap: 2rem;
    width: 100%;
}
}

@media only screen and (max-width: 768px) {
    .containerGridAttachFile{
        margin-top: 20px;
        display: grid;
        grid-template-columns: repeat(1, minmax(0, 1fr));
        row-gap: 1.5rem;
        width: 100%;
    }
    .containerGridOtherFiles{
        margin-top: 20px;
        display: grid;
        grid-template-columns: repeat(3, minmax(0, 1fr));
        column-gap: 2.25rem;
        width: 100%;
    }
}

.mediaTitle{
    margin-top: 20px;
    font-size: 14px;
}
.other-container{
    display: flex;
    flex-direction: row;
}

.file-title{
    font-size:15px;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 8px;
    cursor: pointer;
}

.close{
    display: flex;
    margin-left: auto;
    cursor: pointer;
}

.closeSpan{
    height: 27px;
    width: 27px;
}

.attachSizes{
    display:flex;
    font-size: 14px;
    font-style: italic;
    color: #959595;
}
